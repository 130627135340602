import React from 'react';
import Layout from "../../components/layout";

const Morogoro = () => {
  return (
    <Layout>
      <h1>Mji Mpya</h1>
      <h2>Instructors</h2>
      <p>Senpai Hassan Mwembe</p>

      <p><strong>Class Times</strong><br/>
        <strong>Monday to Friday:</strong> 1800hrs to 2030hrs.
      </p>

      <p><strong>Price</strong><br/>
        Tshs 5000/- monthly to be paid before beginning of the month. (1 USD is equal to approx. 1500/- Tshs.)
      </p>

      <p><strong>Location</strong><br/>
        At CCM office within Mji Mpya Market Complex.</p>

      <hr/>

      <h1>Sokoine</h1>
      <h2>Instructors</h2>
      <p>Senpai Prosper</p>

      <p><strong>Class Times</strong><br/>
        <strong>Monday to Friday:</strong> 1700hrs to 1900hrs
      </p>

      <p><strong>Price</strong><br/>
        Tshs 5000/- monthly to be paid before beginning of the month. (1 USD is equal to approx. 1500/- Tshs.)
      </p>

      <p><strong>Location</strong><br/>
        At Sokoine Agriculture Unversity, in the Tennis Courts.</p>

      <hr/>

      <h1>Mbeya</h1>
      <h2>Instructors</h2>
      <p>Senpai Kennedy</p>

      <p><strong>Class Times</strong><br/>
        <strong>Monday to Friday:</strong> 1800hrs to 2100hrs
      </p>

      <p><strong>Price</strong><br/>
        Tsh 5000/- monthly to be paid before beginning of the month. (1 USD is equal to approx. 1500/- Tshs.)
      </p>

      <p><strong>Location</strong><br/>
        800km from Dar City. South West of Dar City. At Mwanjelwa area Arage Secondary School.</p>
    </Layout>
  );
}

export default Morogoro;
